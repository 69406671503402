import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

export default function Header() {
  const location = useLocation();

  const config = useSelector((state) => state.config);
  const base_url = useSelector((state) => state.config.base_url);

  const [activeLink, setActiveLink] = useState("home");

  useEffect(() => {
    let path = location.pathname.split("/");
    let url = path[1];

    switch (path[2]) {
      case undefined:
        setActiveLink("home");
        break;
      case 'teams':
        setActiveLink("teams");
        break;
        case 'fixtures':
        setActiveLink("fixtures");
        break;
    }

    
  }, [location]);

  return (
    <nav className="bg-white shadow-lg sticky top-0 z-[999]">
      <div className="hidden h-[50px] md:flex justify-end px-[50px] gap-[20px]">
        {/* <a
          href=""
          className="border px-[20px] py-[5px] rounded-full text-sm  text-white self-center bg-base-100"
        >
          {" "}
          Register your team
        </a> */}
        {/* <a
          href=""
          className="border px-[20px] py-[5px] rounded-full text-sm  text-white self-center bg-base-100"
        >
          {" "}
          Get Your Competition Site
        </a> */}
      </div>
      <div className="border flex md:block bg-base-100  h-full md:h-[70px] relative  p-[10px] md:p-0 ">
        <NavLink
          to="">
        <img
          className="bg-white   border-2  w-[80px] md:w-[120px]  md:absolute top-[-30px] left-[50px]"
          alt=""
          src={`${base_url}uploads/${config.logo}`}
        />

          </NavLink>
        <div className="px-[20px] md:ps-[200px]  lg:px-[200px] flex h-full font-extrabold self-center">
          <h1 className="text-xl md:text-3xl self-center text-white font-pilat ">
            {config.name}
          </h1>
        </div>
      </div>

      <div className=" h-[50px] md:h-[60px] flex justify-around md:justify-center  lg:px-[200px] md:gap-[5px]">
        <NavLink
          to=""
          className={`self-end border-b-4 ${
            activeLink == "home" ? " border-base-100" : "border-transparent"
          }    py-[5px] px-[10px] md:px-[30px] text-base-100 text-sm md:text-base`}
        >
          Home
        </NavLink>
        <NavLink
          to="teams"
          className={`self-end border-b-4 ${
            activeLink == "teams" ? " border-base-100" : "border-transparent"
          }    py-[5px] px-[10px] md:px-[30px] text-base-100 text-sm md:text-base`}
        >
          Teams
        </NavLink>
        <NavLink
          to="fixtures"
          className={`self-end border-b-4 ${
            activeLink == "fixtures" ? " border-base-100" : "border-transparent"
          }    py-[5px] px-[10px] md:px-[30px] text-base-100 text-sm md:text-base`}
        >
          Fixtures
        </NavLink>
        {/* <NavLink
          to="stats"
          className="self-end border-b-4 border-transparent py-[5px] px-[10px] md:px-[30px] text-base-100 text-sm md:text-base"
        >
          Stats{" "}
        </NavLink>
        <NavLink
          to="awards"
          className="self-end border-b-4 border-transparent py-[5px] px-[10px] md:px-[30px] text-base-100 text-sm md:text-base"
        >
          Awards
        </NavLink> */}
      </div>
    </nav>
  );
}
