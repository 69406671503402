import React from 'react'
import laptop from "../../../assets/images/laptop.png"



export default function Hero() {
  return (
    <section data-theme="versuz" className="hero flex flex-col-reverse lg:flex-row px-[40px] py-[110px] pt-[220px] max-w-[1400px] mx-auto gap-3">
        <div className=" max-w-[650px] " >
          <h1 className="font-pilat text-4xl font-black text-white tracking-wide text-center lg:text-start hidden md:block">
            Manage Your Sports Competitions <span className="font-pilat text-lime-500">With Ease</span>
          </h1>
          
          <h1 className="font-pilat md:hidden  text-xl font-black text-white  text-center lg:text-start">
             <span className="font-pilat text-lime-500">Seamless</span>   Management for
             Sports Competitions
          </h1>
          <p className="text-white leading-normal mt-[22px] text-center lg:text-start">
          Versuz is a digital sports hub with powerful tools to simplify planning and organizing of sports competitions, automate admin tasks, and boost audience engagement. Our solutions handle the heavy lifting saving time and allowing you focus your resources on delivering exceptional events.
          </p>
          <div className="flex flex-col md:flex-row  mt-[30px] gap-[20px] justify-center lg:justify-start">

            <a 
            href="https://competition-manager.versuz.co/signup.php"
            target="_blank"
            className=" py-[9px] !hover:none px-[40px] h-[40px] rounded-full bg-lime-500 text-lime-950 text-sm font-bold text-center">
              Create an account
              </a>
            {/* <a href="" className="btn px-[40px] h-[40px] rounded-full bg-white text-purple font-bold">Explore Solutions</a> */}
          </div>

        </div>
        <img src={laptop} alt="" />
      </section>
  )
}
